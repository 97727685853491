const constants = {
  LOCAL_STORAGE_KEYS: {
    THEME_TYPE: 'THEME_TYPE',
    USER_TOKEN: 'USER_TOKEN'
  },
  THEME_TYPES: {
    LIGHT: 'light',
    DARK: 'dark'
  },
  ENUM_DATA: {
    GOAT_GENDERS: {
      MALE: 'MALE',
      FEMALE: 'FEMALE'
    },
    GOAT_STATUSES: {
      ACTIVE: 'ACTIVE',
      TRADED: 'TRADED',
      DECEASED: 'DECEASED'
    },
    REPRODUCTIVE_REPORT_STATUSES: {
      INITIALIZED: 'INITIALIZED',
      IN_PROGRESS: 'IN_PROGRESS',
      FAILED: 'FAILED',
      SUCCEEDED: 'SUCCEEDED',
      FINISHED: 'FINISHED'
    }
  }
}

export default constants
