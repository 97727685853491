const formatCreateObject = ({
  goat_id,
  value,
  notes,
  production_report_photos
}) => {
  return {
    variables: {
      object: {
        goat_id,
        value,
        notes: notes || null,
        production_report_photos: { data: production_report_photos }
      }
    }
  }
}

const formatUpdateObject = ({
  id,
  goat_id,
  value,
  notes,
  production_report_photos
}) => {
  return {
    variables: {
      id: id,
      goat_id: goat_id,
      value: value,
      notes: notes || null,
      production_report_photos: production_report_photos
        .filter(photo => !photo.id)
        .map(photo => ({ ...photo, production_report_id: id }))
    }
  }
}

const formatDeleteObject = id => {
  return {
    variables: {
      id,
      deleted_at: new Date()
    }
  }
}

export default {
  formatCreateObject,
  formatUpdateObject,
  formatDeleteObject
}
