import { hapiConfig } from 'config'
import { authUtils } from 'utils'

const get = endpoint =>
  new Promise(async (resolve: Function, reject: Function) => {
    try {
      const request = await window.fetch(`${hapiConfig.url}${endpoint}`, {
        method: 'GET',
        headers: {
          authorization: authUtils.getToken()
        }
      })

      const response = await request.json()

      if (!request.ok) {
        throw new Error(response.message)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

const post = (endpoint, data, isFormData = false) =>
  new Promise(async (resolve: Function, reject: Function) => {
    try {
      const request = await window.fetch(`${hapiConfig.url}${endpoint}`, {
        method: 'POST',
        body: isFormData ? data : JSON.stringify(data),
        headers: isFormData
          ? {
              authorization: authUtils.getToken()
            }
          : {
              'Content-Type': 'application/json',
              authorization: authUtils.getToken()
            }
      })

      const response = await request.json()

      if (!request.ok) {
        throw new Error(response.message)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

const fileUpload = (endpoint, file, method = 'PUT') =>
  new Promise(async (resolve: Function, reject: Function) => {
    try {
      const response = await window.fetch(endpoint, {
        method,
        body: file
      })

      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`)
      }

      resolve(response)
    } catch (err) {
      reject(err.message)
    }
  })

export default {
  get,
  post,
  fileUpload
}
