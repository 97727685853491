import { createMuiTheme } from '@material-ui/core/styles'

export default type =>
  createMuiTheme({
    palette: {
      type: type || 'light',
      primary: {
        main: '#3F6EB4'
      },
      secondary: {
        main: '#EC6CA3'
      },
      gray: {
        main: '#d3d3d3d3'
      }
    }
  })
