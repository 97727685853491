import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem
} from '@material-ui/core'
import {
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useAuth } from 'context/auth/auth'
import { Link } from 'components'

function DesktopAppBar({ handleDrawerOpen, open }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { logout } = useAuth()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleAccountClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleAccountClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleShowProfile = useCallback(() => {
    history.push('/profile')
    handleAccountClose()
  }, [history, handleAccountClose])

  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}
    >
      <Toolbar>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          className={classes.content}
        >
          <Box display='flex' flexDirection='row' alignItems='center'>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <MenuIcon />
            </IconButton>

            <Link to='/' color='inherit' variant='h6' className={classes.link}>
              Capralín
            </Link>
          </Box>

          <>
            <AccountCircleIcon onClick={handleAccountClick} />
            <Menu
              id='profile-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleAccountClose}
              classes={{
                paper: classes.menuPaper
              }}
            >
              <MenuItem onClick={handleShowProfile}>{t('profile')}</MenuItem>
              <MenuItem onClick={logout}>{t('logout')}</MenuItem>
            </Menu>
          </>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles(theme => {
  const drawerWidth = 260

  return {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    content: {
      flex: 1
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: 'none'
    }
  }
})

DesktopAppBar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default DesktopAppBar
