import React, { lazy, Suspense } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'

import { FullPageLoading } from 'components'
import { useUser } from 'context/auth/user'
import { useThemeType } from 'context/theme'
import { generateApolloClient, themeConfig } from 'config'

const Authenticated = lazy(() => import('./screens/Authenticated'))
const Unauthenticated = lazy(() => import('./screens/Unauthenticated'))

function App() {
  const { user } = useUser()
  const { themeType } = useThemeType()

  return (
    <ApolloProvider client={generateApolloClient()}>
      <ThemeProvider theme={themeConfig(themeType)}>
        <CssBaseline />
        <Suspense fallback={<FullPageLoading />}>
          {user ? <Authenticated /> : <Unauthenticated />}
        </Suspense>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
