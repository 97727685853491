import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  AddAPhoto as AddAPhotoIcon,
  HighlightOff as HighlightOffIcon
} from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { useTranslation } from 'react-i18next'

import { BaseTextField, FilePreview } from 'components'

function FileUploadTextField({
  onFileChange,
  file,
  loading = false,
  allowDelete = false,
  onDelete = () => {},
  name = 'FileUploadTextField',
  ...props
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.container}
      >
        <>
          <BaseTextField name={name} {...props} />

          <input
            className={classes.input}
            id={name}
            type='file'
            accept='image/*'
            onChange={onFileChange}
          />

          <label className={classes.label} htmlFor={name}>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <Tooltip title={t('takePhoto')}>
                <Box px={2} py={1}>
                  <AddAPhotoIcon />
                </Box>
              </Tooltip>

              {allowDelete && (
                <Tooltip title={t('delete')}>
                  <IconButton onClick={onDelete}>
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </label>
        </>
      </Box>

      {file && !loading && <FilePreview value={file} />}

      {loading && <Skeleton className={classes.fileContainer} />}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  container: { width: '100%' },
  input: { display: 'none' },
  label: { flex: 1 },
  fileContainer: {
    height: 150,
    margin: theme.spacing(1)
  }
}))

FileUploadTextField.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  file: PropTypes.string,
  loading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  name: PropTypes.string,
  props: PropTypes.object
}

export default FileUploadTextField
