const en = {
  translation: {
    userName: 'Username',
    password: 'Password',
    login: 'Login',
    language: 'Language',
    required_validation: 'The field is required',
    invalid_credentials: 'Invalid username or password',
    logout: 'Logout',
    welcome: 'Welcome',
    home: 'Home',
    goat: 'Goat',
    goats: 'Goats',
    add: 'Add',
    show: 'Show',
    reports: 'Reports',
    physical: 'Physical',
    reproductive: 'Reproductive',
    production: 'Production',
    medical: 'Medical',
    initialized: 'Initialized',
    progress: 'In progress',
    succeded: 'Succeded',
    finished: 'Finished',
    failed: 'Failed',
    create: 'Create',
    MALE: 'Male',
    MALES: 'Males',
    FEMALE: 'Female',
    FEMALES: 'Females',
    ACTIVE: 'Active',
    TRADED: 'Traded',
    DECEASED: 'Deceased',
    INITIALIZED: 'Initialized',
    IN_PROGRESS: 'In progress',
    FAILED: 'Failed',
    SUCCEEDED: 'Succeded',
    FINISHED: 'Finished',
    goat_number: 'Goat number',
    gender: 'Gender',
    breed: 'Breed',
    mother_number: 'Mother number',
    father_number: 'Father number',
    number_validation: 'Only allow numbers',
    goat_created: 'Created succeeded',
    birthdate: 'Birthdate',
    description: 'Description',
    list: 'List',
    theme: 'Theme',
    light: 'Light',
    dark: 'Dark',
    loading: 'Loading...',
    noOptions: 'No options',
    id_number: 'Number',
    labelRowsSelect: 'Rows',
    firstTooltip: 'First',
    lastTooltip: 'Last',
    previousTooltip: 'Previous',
    nextTooltip: 'Next',
    emptyDataSourceMessage: 'No data to display',
    searchTooltip: 'Search',
    searchPlaceholder: 'Search',
    fatalError: 'Fatal error',
    edit: 'Edit',
    close: 'Close',
    save: 'Save',
    delete: 'Delete',
    id: 'Id',
    status: 'Status',
    physicalReports: 'Physical reports',
    urlNotFound: 'The URL does not exist on this site.',
    body: 'Body',
    famacha: 'Famacha',
    body_condition: 'Body condition',
    famacha_condition: 'Famacha condition',
    linfadenitis: 'Linfadenitis',
    linfadenitis_condition: 'Linfadenitis condition',
    weight: 'Weight(kg)',
    notes: 'Notes',
    true: 'True',
    false: 'False',
    skip: 'Skip',
    physicalReport: 'Physical report',
    created_at: 'Created at',
    medicalReport: 'Medical report',
    medicalReports: 'Medical reports',
    report: 'Report',
    date: 'Date',
    photos: 'Photos',
    photo_detail: 'Photo detail',
    photo_required_validation: 'Photo is required',
    photo_detail_required_validation: 'Detail is required',
    addPhoto: 'Add photo',
    takePhoto: 'Take photo',
    guest: 'Guest',
    guestLogin: 'Login as guest',
    user: 'User',
    users: 'Users',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    role: 'Role',
    profile: 'Profile',
    productionReports: 'Production reports',
    reproductiveReports: 'Reproductive reports',
    productionReport: 'Production report',
    reproductiveReport: 'Reproductive report',
    goatNotFound: 'Goat not found',
    physicalReportNotFound: 'Physical Report not found',
    medicalReportNotFound: 'Medical report not found',
    productionReportNotFound: 'Production report not found',
    reproductiveReportNotFound: 'Reproductive report not found',
    inProgress: 'In progress',
    infoByGoatId: 'Get info by goat number',
    search: 'Search',
    generalInformation: 'General information',
    familyTree: 'Family tree',
    mother: 'Mother',
    father: 'Father',
    childrens: 'Childrens',
    children: 'Children',
    comments: 'Comments',
    comment: 'Comment',
    commentsDetail:
      'Section to propose functionalities, improvements, ideas or to give feedback about the application.',
    title: 'Title',
    detail: 'Detail',
    info: 'Info',
    aboutUs: 'About us',
    aboutUsDetail:
      'Capralín is a Costa Rican brand mainly focused on the commercialization of goat milk and derivatives such as yogurt, cheese ... Currently we are also looking to improve the genetics and qualities of our goats and we know how important the data is, for this reason we are creating this application.',
    goal: 'Goal',
    goalDetail:
      'Our personal goal is to improve the genetics of our goats and document the whole process to be able to see the progress of our effort and in the future sell goats with good genetics and well documented, the other goal is that more people join us to create a community where every goat has its own history and is easy to access and share if necessary.',
    howWorks: 'How works',
    howWorksDetail:
      'Capralín is an application that helps the administration of goats keeping track of physical, medical, productive and reproductive reports with the objective of collecting useful information to create graphs, averages, family tree ... in addition Capralín is a multi tenant application this It means that we all use the same application but we each have our private data, this means that I can only see the information of my goats.',
    advantage: 'Advantage',
    advantageDetail:
      'One of the main and most important advantage is the ability to transfer goats, very useful when buying, selling or exchanging goats between people using Capralín',
    value: 'Value',
    location: 'Location',
    locations: 'Locations',
    emailOrUsername: 'Email or Username'
  }
}

export default en
