import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { awsConfig } from 'config'

function FileDetail({ detail, value, divider = true }) {
  const [isZoomed, setIsZoomed] = useState(false)
  const classes = useStyles({ divider, isZoomed })

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  if (!value) {
    return null
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-around'
      alignItems='center'
      className={classes.container}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.box}
      >
        <Typography variant='caption' gutterBottom>
          {detail}
        </Typography>
      </Box>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.box}
      >
        <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            my={2}
            className={classes.box}
          >
            <img
              src={`${awsConfig.s3.url}${value}`}
              alt={value}
              className={classes.img}
            />
          </Box>
        </ControlledZoom>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    borderBottom: ({ divider }) => (divider ? 'solid' : 'none'),
    borderBottomColor: ({ divider }) =>
      divider ? theme.palette.gray.main : 'transparent',
    borderBottomWidth: ({ divider }) => (divider ? 1 : 0)
  },
  box: {
    flex: 1
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 150
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
      height: ({ isZoomed }) => (isZoomed ? '100%' : 150)
    }
  }
}))

FileDetail.propTypes = {
  detail: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  divider: PropTypes.bool
}

export default FileDetail
