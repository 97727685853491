import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@material-ui/core'

const LinkRef = React.forwardRef(function Link(props, ref) {
  return (
    <MuiLink component={RouterLink} ref={ref} underline='none' {...props} />
  )
})

LinkRef.propTypes = {
  props: PropTypes.object
}

export default LinkRef
