import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

function LabelValue({ label, value }) {
  const classes = useStyles()

  if (!value) {
    return null
  }

  return (
    <Typography gutterBottom component='h5'>
      {`${label}:`}
      <Typography display='inline' className={classes.text}>
        {value}
      </Typography>
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  text: {
    fontWeight: '300',
    marginLeft: theme.spacing(1)
  }
}))

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default LabelValue
