const formatCreateObject = ({ goat_id, report, medical_report_photos }) => {
  return {
    variables: {
      object: {
        goat_id,
        report,
        medical_report_photos: { data: medical_report_photos }
      }
    }
  }
}

const formatUpdateObject = ({ id, goat_id, report, medical_report_photos }) => {
  return {
    variables: {
      id,
      goat_id,
      report,
      medical_report_photos: medical_report_photos
        .filter(photo => !photo.id)
        .map(photo => ({ ...photo, medical_report_id: id }))
    }
  }
}

const formatDeleteObject = id => {
  return {
    variables: {
      id,
      deleted_at: new Date()
    }
  }
}

export default {
  formatCreateObject,
  formatUpdateObject,
  formatDeleteObject
}
