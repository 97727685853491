import jwt from 'jsonwebtoken'

import { jwtConfig, constants } from 'config'

const setToken = token =>
  window.localStorage.setItem(constants.LOCAL_STORAGE_KEYS.USER_TOKEN, token)

const getToken = () =>
  window.localStorage.getItem(constants.LOCAL_STORAGE_KEYS.USER_TOKEN) || ''

const cleanToken = () =>
  window.localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.USER_TOKEN)

const decodedToken = (currToken = null) => {
  const token = currToken || getToken()

  if (!token) {
    return null
  }

  try {
    const decoded = jwt.verify(
      token.replace('Bearer ', ''),
      jwtConfig.publicKey
    )

    return decoded
  } catch (err) {
    return null
  }
}

export default {
  setToken,
  getToken,
  cleanToken,
  decodedToken
}
