import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useSizes } from 'hooks'

function Container({ children }) {
  const { smDown } = useSizes()
  const classes = useStyles({ smDown })

  return (
    <Box component='main' className={classes.content}>
      <Box className={classes.toolbar} />
      {children}
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: ({ smDown }) =>
    smDown
      ? { ...theme.mixins.toolbar }
      : {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar
        }
}))

Container.propTypes = {
  children: PropTypes.element.isRequired
}

export default Container
