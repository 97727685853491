const formatCreateObject = ({
  goat_id,
  body_condition,
  famacha_condition,
  linfadenitis_condition,
  weight,
  notes,
  physical_report_photos
}) => {
  return {
    variables: {
      object: {
        goat_id,
        body_condition: body_condition || null,
        famacha_condition: famacha_condition || null,
        linfadenitis_condition:
          typeof linfadenitis_condition === 'boolean'
            ? linfadenitis_condition
            : null,
        weight: weight || null,
        notes: notes || null,
        physical_report_photos: { data: physical_report_photos }
      }
    }
  }
}

const formatUpdateObject = ({
  id,
  goat_id,
  body_condition,
  famacha_condition,
  linfadenitis_condition,
  weight,
  notes,
  physical_report_photos
}) => {
  return {
    variables: {
      id: id,
      goat_id: goat_id,
      body_condition: body_condition || null,
      famacha_condition: famacha_condition || null,
      linfadenitis_condition:
        typeof linfadenitis_condition === 'boolean'
          ? linfadenitis_condition
          : null,
      weight: weight || null,
      notes: notes || null,
      physical_report_photos: physical_report_photos
        .filter(photo => !photo.id)
        .map(photo => ({ ...photo, physical_report_id: id }))
    }
  }
}

const formatDeleteObject = id => {
  return {
    variables: {
      id,
      deleted_at: new Date()
    }
  }
}

export default {
  formatCreateObject,
  formatUpdateObject,
  formatDeleteObject
}
