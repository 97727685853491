const formatCreateObject = ({
  id_number,
  birthdate,
  gender,
  mother_id,
  father_id,
  breed_id,
  description,
  location_id,
  goat_photos
}) => {
  return {
    variables: {
      object: {
        id_number: id_number,
        birthdate: birthdate,
        gender: gender,
        mother_id: mother_id || null,
        father_id: father_id || null,
        breed_id: breed_id,
        description: description || null,
        location_id: location_id,
        goat_photos: { data: goat_photos }
      }
    }
  }
}

const formatUpdateObject = ({
  id,
  id_number,
  birthdate,
  gender,
  status,
  mother_id,
  father_id,
  breed_id,
  description,
  location_id,
  goat_photos
}) => {
  return {
    variables: {
      id,
      id_number,
      birthdate,
      location_id,
      gender,
      status,
      mother_id: mother_id || null,
      father_id: father_id || null,
      breed_id,
      description: description || null,
      goat_photos: goat_photos
        .filter(photo => !photo.id)
        .map(photo => ({ ...photo, goat_id: id }))
    }
  }
}

const formatDeleteObject = id => {
  return {
    variables: {
      id,
      deleted_at: new Date()
    }
  }
}

export default {
  formatCreateObject,
  formatUpdateObject,
  formatDeleteObject
}
