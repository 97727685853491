const es = {
  translation: {
    userName: 'Usuario',
    password: 'Contraseña',
    login: 'Ingresar',
    language: 'Idioma',
    required_validation: 'El campo es obligatorio',
    invalid_credentials: 'Usuario o contraseña incorrecto',
    logout: 'Salir',
    welcome: 'Bienvenido',
    home: 'Inicio',
    goat: 'Cabra',
    goats: 'Cabras',
    add: 'Agregar',
    show: 'Mostrar',
    reports: 'Reportes',
    physical: 'Físico',
    reproductive: 'Reproductivo',
    production: 'Productivo',
    medical: 'Medico',
    initialized: 'Inicializado',
    progress: 'En progreso',
    succeded: 'Confirmado',
    finished: 'Terminado',
    failed: 'Fallido',
    create: 'Crear',
    MALE: 'Macho',
    MALES: 'Machos',
    FEMALE: 'Hembra',
    FEMALES: 'Hembras',
    ACTIVE: 'Activo',
    TRADED: 'Vendido',
    DECEASED: 'Fallecido',
    INITIALIZED: 'Inicializado',
    IN_PROGRESS: 'En progreso',
    FAILED: 'Fallido',
    SUCCEEDED: 'Confirmado',
    FINISHED: 'Terminado',
    goat_number: 'Número de cabra',
    gender: 'Género',
    breed: 'Raza',
    mother_number: 'Numero de madre',
    father_number: 'Numero de padre',
    number_validation: 'Solo se permiten números',
    goat_created: 'Creada con éxito',
    birthdate: 'Nacimiento',
    description: 'Descripción',
    list: 'Lista',
    theme: 'Tema',
    light: 'Claro',
    dark: 'Oscuro',
    loading: 'Cargando...',
    noOptions: 'Sin opciones',
    id_number: 'Número',
    labelRowsSelect: 'Filas',
    firstTooltip: 'Primera',
    lastTooltip: 'Ultima',
    previousTooltip: 'Anterior',
    nextTooltip: 'Siguiente',
    emptyDataSourceMessage: 'No hay datos para mostrar',
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    fatalError: 'Error fatal',
    edit: 'Editar',
    close: 'Cerrar',
    save: 'Guardar',
    delete: 'Eliminar',
    id: 'Id',
    status: 'Estado',
    physicalReports: 'Reportes físicos',
    urlNotFound: 'La URL no existe en este sitio.',
    body: 'Corporal',
    famacha: 'Famacha',
    body_condition: 'Condición Corporal',
    famacha_condition: 'Escala de Famacha',
    linfadenitis: 'Linfadenitis',
    linfadenitis_condition: 'Linfadenitis',
    weight: 'Peso(kg)',
    notes: 'Notas',
    true: 'Positivo',
    false: 'Negativo',
    skip: 'Ignorar',
    physicalReport: 'Reporte físico',
    created_at: 'Fecha de creación',
    medicalReport: 'Reporte médico',
    medicalReports: 'Reportes médicos',
    report: 'Reporte',
    date: 'Fecha',
    photos: 'Fotos',
    photo_detail: 'Descripción de la foto',
    photo_required_validation: 'La foto es obligatoria',
    photo_detail_required_validation: 'La descripción es obligatoria',
    addPhoto: 'Agregar foto',
    takePhoto: 'Tomar foto',
    guest: 'Invitado',
    guestLogin: 'Ingresar como invitado',
    user: 'Usuario',
    users: 'Usuarios',
    first_name: 'Nombre',
    last_name: 'Apellido',
    email: 'Correo',
    role: 'Rol',
    profile: 'Perfil',
    productionReports: 'Reportes productivos',
    reproductiveReports: 'Reportes reproductivos',
    productionReport: 'Reporte productivo',
    reproductiveReport: 'Reporte reproductivo',
    goatNotFound: 'No se encontro la cabra',
    physicalReportNotFound: 'No se encontro el reporte físico',
    medicalReportNotFound: 'No se encontro el reporte médico',
    productionReportNotFound: 'No se encontro el reporte productivo',
    reproductiveReportNotFound: 'No se encontro el reporte reproductivo',
    inProgress: 'En progreso',
    infoByGoatId: 'Obtener información por número de cabra',
    search: 'Buscar',
    generalInformation: 'Información general',
    familyTree: 'Árbol genealógico',
    mother: 'Madre',
    father: 'Padre',
    childrens: 'Hijos',
    children: 'Hijo',
    comments: 'Comentarios',
    comment: 'Comentario',
    commentsDetail:
      'Sección para proponer funcionalidades, mejoras, ideas o para dar retroalimentación acerca de la aplicación.',
    title: 'Titulo',
    detail: 'Detalle',
    info: 'Información',
    aboutUs: 'Sobre nosotros',
    aboutUsDetail:
      'Capralín es una marca costarricense principalmente enfocada en la comercialización de leche de cabra y derivados como yogurt, queso... Actualmente buscamos tambien mejorar la genética y cualidades de nuestras cabras y sabemos lo importante que son los datos y todo lo que se puede extraer de ellos, por esta razón estamos creando esta aplicación.',
    goal: 'Meta',
    goalDetail:
      'Nuestra meta personal es mejorar la genética de nuestras cabras y documentar todo el proceso para poder ver el avance de nuestro esfuerzo y en un futuro llegar a vender cabras con buena genética y bien documentadas, la otra meta es la de conjunto que es llegar a que más personas se sumen y podamos crear una comunidad donde cada cabra que exista tenga su propia historia y sea fácil de acceder a ella y compartirla si fuera necesario.',
    howWorks: '¿Cómo funciona?',
    howWorksDetail:
      'Capralín es una aplicación que ayuda a la administración de cabras llevando un control de reportes físicos, médicos, productivos y reproductivos con el objetivo de recolectar información útil para poder crear graficas, promedios, árbol genealógicos... además Capralín es una aplicación multi inquilino esto quiere decir que todos usamos la misma aplicación pero cada uno tenemos nuestros datos privados, esto quiere decir que solo puedo ver la información de mis cabras.',
    advantage: 'Ventajas',
    advantageDetail:
      'Una de la principales y más importante ventaja es la habilidad de poder transferir cabras, muy útil a la hora de comprar, vender o intercambiar cabras entre personas que usen Capralín.',
    value: 'Valor',
    location: 'Ubicación',
    locations: 'Ubicaciones',
    emailOrUsername: 'Correo o Usuario'
  }
}

export default es
