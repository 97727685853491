import React, { useCallback } from 'react'
import { MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { BaseTextField } from 'components'
import { languages } from 'api/data/data.json'

function LanguageSelect() {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  const handleOnChange = useCallback(
    ev => {
      i18n.changeLanguage(ev.target.value)
      moment.locale(ev.target.value)
    },
    [i18n]
  )

  return (
    <BaseTextField
      select
      fullWidth={false}
      label={t('language')}
      onChange={handleOnChange}
      value={i18n.language}
      className={classes.root}
    >
      {Object.keys(languages).map(key => {
        const { value, label } = languages[key]
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </BaseTextField>
  )
}

const useStyles = makeStyles({
  root: {
    width: 120
  }
})

export default LanguageSelect
