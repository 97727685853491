import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useTheme } from '@material-ui/core/styles'

function Head({ title }) {
  const {
    palette: {
      primary: { main: color }
    }
  } = useTheme()

  return (
    <Helmet
      titleTemplate={`%s | Capralín`}
      title={title}
      meta={[
        {
          name: `theme-color`,
          content: color
        }
      ]}
    />
  )
}

Head.propTypes = {
  title: PropTypes.string.isRequired
}

export default Head
