import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, Slide } from '@material-ui/core'

const backgroundColors = {
  success: '#2ecc71',
  error: '#e74c3c'
}

function BaseSnackbar({ isVisible, message, type = 'success' }) {
  return (
    <Snackbar
      open={isVisible}
      TransitionComponent={props => <Slide {...props} direction='up' />}
      message={<span id='message-id'>{message}</span>}
      ContentProps={{
        style: { backgroundColor: backgroundColors[type] }
      }}
    />
  )
}

BaseSnackbar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error'])
}

export default BaseSnackbar
