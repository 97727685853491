import React, {
  useReducer,
  createContext,
  useContext,
  useCallback
} from 'react'
import PropTypes from 'prop-types'

import { authUtils, fetchUtils } from 'utils'

const AuthContext = createContext()

function AuthProvider(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: {
        token: authUtils.getToken(),
        user: authUtils.decodedToken()
      }
    }
  )

  const login = useCallback(async body => {
    try {
      setState({
        loading: true,
        error: null
      })

      const { token } = await fetchUtils.post('auth/login', body)

      authUtils.setToken(token)

      setState({
        loading: false,
        data: {
          token: authUtils.getToken(),
          user: authUtils.decodedToken()
        }
      })
    } catch (error) {
      setState({
        loading: false,
        error
      })
    }
  }, [])

  const guestLogin = useCallback(async () => {
    try {
      setState({
        loading: true,
        error: null
      })

      const { token } = await fetchUtils.get('auth/guestToken')

      authUtils.setToken(token)

      setState({
        loading: false,
        data: {
          token: authUtils.getToken(),
          user: authUtils.decodedToken()
        }
      })
    } catch (error) {
      setState({
        loading: false,
        error
      })
    }
  }, [])

  const logout = useCallback(() => {
    authUtils.cleanToken()
    setState({
      data: {
        ...state.data,
        token: authUtils.getToken(),
        user: authUtils.decodedToken()
      }
    })
  }, [state.data])

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        guestLogin,
        ...state
      }}
      {...props}
    />
  )
}

function useAuth() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

AuthProvider.propTypes = {
  props: PropTypes.object
}

export { AuthProvider, useAuth }
