import { useState, useEffect } from 'react'

const useTempVisible = (initialState = false, wait = 3000) => {
  const [isVisible, setIsVisible] = useState(initialState)

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsVisible(false)
      }, wait)
    }
  }, [isVisible, wait])

  return {
    isVisible,
    setIsVisible
  }
}

export default useTempVisible
