import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import { useSizes } from 'hooks'

import MobileAppBar from './Mobile/AppBar'
import MobileDrawer from './Mobile/Drawer'
import DesktopAppBar from './Desktop/AppBar'
import DesktopDrawer from './Desktop/Drawer'
import Container from './Shared/Container'

function Layout({ children }) {
  const [open, setOpen] = useState(false)
  const { smDown } = useSizes()

  const handleDrawerToggle = useCallback(() => {
    setOpen(open => !open)
  }, [])

  const handleDrawerOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleDrawerClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <Box display='flex'>
      {smDown ? (
        <>
          <MobileAppBar handleDrawerToggle={handleDrawerToggle} />
          <MobileDrawer handleDrawerToggle={handleDrawerToggle} open={open} />
          <Container>{children}</Container>
        </>
      ) : (
        <>
          <DesktopAppBar handleDrawerOpen={handleDrawerOpen} open={open} />
          <DesktopDrawer handleDrawerClose={handleDrawerClose} open={open} />
          <Container>{children}</Container>
        </>
      )}
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired
}

export default Layout
