import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { awsConfig } from 'config'

function FileUpload({ value }) {
  const [isZoomed, setIsZoomed] = useState(false)
  const classes = useStyles({ isZoomed })

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  if (!value) {
    return null
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      className={classes.container}
    >
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <img
          src={`${awsConfig.s3.url}${value}`}
          alt={value}
          className={classes.img}
        />
      </ControlledZoom>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2)
  },
  img: {
    height: ({ isZoomed }) => (isZoomed ? '100%' : 150)
  }
}))

FileUpload.propTypes = {
  value: PropTypes.string
}

export default FileUpload
