import React from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer, Divider } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import DrawerOptions from '../../Shared/DrawerOptions'

function MobileDrawer({ handleDrawerToggle, open }) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Box component='nav' className={classes.drawer}>
      <Drawer
        variant='temporary'
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={open}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true
        }}
      >
        <Box className={classes.toolbar} />
        <Divider />
        <DrawerOptions onClose={handleDrawerToggle} />
      </Drawer>
    </Box>
  )
}

const useStyles = makeStyles(theme => {
  const drawerWidth = 260
  return {
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    toolbar: theme.mixins.toolbar
  }
})

MobileDrawer.propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default MobileDrawer
