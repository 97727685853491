import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

function SkeletonField() {
  const classes = useStyles()

  return (
    <Skeleton
      variant='rect'
      width='100%'
      height={50}
      className={classes.root}
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0)
  }
}))

export default SkeletonField
