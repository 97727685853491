import React from 'react'
import ReactDOM from 'react-dom'

import AuthProviders from 'context/auth'
import { ThemeTypeProvider } from 'context/theme'

import App from './App'
import * as serviceWorker from './serviceWorker'

import 'config/i18n'
import 'config/moment'

ReactDOM.render(
  <ThemeTypeProvider>
    <AuthProviders>
      <App />
    </AuthProviders>
  </ThemeTypeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
