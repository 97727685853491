import React from 'react'
import PropTypes from 'prop-types'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Box
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

function BaseExpansionPanel({ title, detail }) {
  const { t } = useTranslation()

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t(title)}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Box>
          <Typography>{t(detail)}</Typography>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

BaseExpansionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired
}

export default BaseExpansionPanel
