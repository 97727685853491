import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  Tooltip
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useUser } from 'context/auth/user'
import { Link } from 'components'
import { menu } from 'api/data/data.json'

function DrawerOptions({ onClose = () => {} }) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const {
    user: { first_name }
  } = useUser()

  return (
    <List
      component='nav'
      subheader={
        <ListSubheader component='div' id='nested-list-subheader'>
          {t(first_name)}
        </ListSubheader>
      }
    >
      {Object.keys(menu).map(key => {
        const { id, label, iconName, path } = menu[key]
        return (
          <ListItem
            key={id}
            to={path}
            color='inherit'
            variant='h6'
            component={Link}
            onClick={onClose}
            selected={pathname.startsWith(path)}
          >
            <ListItemIcon>
              <Tooltip title={t(label)}>
                <Icon>{iconName}</Icon>
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={t(label)} />
          </ListItem>
        )
      })}
    </List>
  )
}

DrawerOptions.propTypes = {
  onClose: PropTypes.func
}

export default DrawerOptions
