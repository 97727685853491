import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function BaseTextField({ readOnly = false, withT = true, ...props }) {
  const { t } = useTranslation()

  if (readOnly) {
    return (
      <TextField
        variant='outlined'
        margin='normal'
        fullWidth
        autoComplete='off'
        InputProps={{
          readOnly
        }}
        label={props.label}
        value={withT ? t(props.value) : props.value}
      />
    )
  }

  return (
    <TextField
      variant='outlined'
      margin='normal'
      fullWidth
      autoComplete='off'
      {...props}
    />
  )
}

BaseTextField.propTypes = {
  readOnly: PropTypes.bool,
  withT: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  props: PropTypes.object
}

export default BaseTextField
