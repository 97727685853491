import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

function useSizes() {
  const theme = useTheme()

  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'))

  return {
    xsDown,
    smDown,
    smUp,
    mdDown,
    xlDown
  }
}

export default useSizes
