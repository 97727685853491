import React, { useCallback } from 'react'
import { Switch } from '@material-ui/core'
import {
  Brightness3 as Brightness3Icon,
  WbSunny as WbSunnyIcon
} from '@material-ui/icons'

import { constants } from 'config'
import { useThemeType } from 'context/theme'

const {
  THEME_TYPES: { LIGHT, DARK }
} = constants

function ThemeTypeSelect() {
  const { themeType, setThemeType } = useThemeType()

  const handleThemeChange = useCallback(
    event => setThemeType(event.target.checked ? DARK : LIGHT),
    [setThemeType]
  )

  return (
    <Switch
      value='theme'
      icon={<WbSunnyIcon color='primary' />}
      checkedIcon={<Brightness3Icon color='primary' />}
      checked={themeType === DARK}
      onChange={handleThemeChange}
    />
  )
}

export default ThemeTypeSelect
