import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { useViewport } from 'hooks'

function FullPageLoading() {
  const { t } = useTranslation()
  const { vh } = useViewport()
  const fullHeight = vh(85)
  const classes = useStyles({ fullHeight })

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      className={classes.container}
    >
      <Typography color='primary' variant='subtitle1'>
        {t('loading')}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    height: ({ fullHeight }) => fullHeight
  }
})

export default FullPageLoading
