import sha512 from 'crypto-js/sha512'

const createFormat = ({
  first_name,
  last_name,
  email,
  user_name,
  password,
  role,
  tenant_id
}) => {
  return {
    variables: {
      object: {
        first_name,
        last_name,
        email,
        user_name,
        password: sha512(password).toString(),
        role,
        tenant_id
      }
    }
  }
}

const updateFormat = ({ id, role }) => {
  return {
    variables: {
      id,
      role
    }
  }
}

const updateProfileFormat = ({
  id,
  first_name,
  last_name,
  email,
  user_name
}) => {
  return {
    variables: {
      id,
      first_name,
      last_name,
      email,
      user_name
    }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id,
      deleted_at: new Date()
    }
  }
}

const loginFormat = ({ emailOrUsername, password }) => {
  return {
    emailOrUsername,
    password: sha512(password).toString()
  }
}

export default {
  createFormat,
  updateFormat,
  updateProfileFormat,
  deleteFormat,
  loginFormat
}
