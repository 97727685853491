import { useCallback, useReducer } from 'react'

import { fetchUtils } from 'utils'

const useFileUpload = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: null
    }
  )

  const upload = useCallback(
    async ({ name, type, file, throwError = false }) => {
      try {
        setState({
          loading: true,
          error: null
        })

        const { url } = await fetchUtils.get(
          `s3/getSignedUrl?name=${name}&type=${type}`
        )

        const response = await fetchUtils.fileUpload(url, file)

        setState({
          data: response,
          loading: false
        })

        return response
      } catch (error) {
        setState({
          error,
          loading: false
        })

        if (throwError) {
          throw new Error(error)
        }
      }
    },
    []
  )

  return {
    ...state,
    upload
  }
}

export default useFileUpload
