import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

function ListSkeleton({ length = 1, height = 50, ...props }) {
  const classes = useStyles()
  const times = [...Array(length).keys()]

  return (
    <>
      {times.map(time => (
        <Skeleton
          key={time}
          variant='rect'
          width='100%'
          height={height}
          className={classes.skeleton}
          {...props}
        />
      ))}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  skeleton: {
    margin: theme.spacing(3, 0)
  }
}))

ListSkeleton.propTypes = {
  length: PropTypes.number,
  height: PropTypes.number,
  props: PropTypes.object
}

export default ListSkeleton
