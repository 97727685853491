import ApolloClient from 'apollo-client'
import { WebSocketLink } from 'apollo-link-ws'
import { HttpLink } from 'apollo-link-http'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

import { authUtils } from 'utils'
import hasuraConfig from '../hasura'

const generateApolloClient = () => {
  const httpLink = new HttpLink({
    uri: hasuraConfig.httpLinkUrl,
    credentials: 'same-origin'
  })

  const wsLink = new WebSocketLink({
    uri: hasuraConfig.wsLinkUrl,
    options: {
      reconnect: true,
      connectionParams: () => {
        return {
          headers: {
            Authorization: authUtils.getToken()
          }
        }
      }
    }
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: authUtils.getToken()
      }
    }
  })

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  return new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache({
      cacheRedirects: {
        Query: {
          goat_by_pk: (_, { id }, { getCacheKey }) =>
            getCacheKey({ id, __typename: 'goat' }),
          physical_report_by_pk: (_, { id }, { getCacheKey }) =>
            getCacheKey({ id, __typename: 'physical_report' }),
          medical_report_by_pk: (_, { id }, { getCacheKey }) =>
            getCacheKey({ id, __typename: 'medical_report' }),
          production_report_by_pk: (_, { id }, { getCacheKey }) =>
            getCacheKey({ id, __typename: 'production_report' }),
          user_by_pk: (_, { id }, { getCacheKey }) =>
            getCacheKey({ id, __typename: 'user' }),
          location_by_pk: (_, { id }, { getCacheKey }) =>
            getCacheKey({ id, __typename: 'location' })
        }
      }
    })
  })
}

export default generateApolloClient
