import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip
} from '@material-ui/core'
import {
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Link } from 'components'
import { useAuth } from 'context/auth/auth'

function MobileAppBar({ handleDrawerToggle }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { logout } = useAuth()
  const { t } = useTranslation()
  const history = useHistory()

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleShowProfile = useCallback(() => {
    history.push('/profile')
    handleClose()
  }, [history, handleClose])

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          className={classes.container}
        >
          <Tooltip title={t('home')}>
            <Link to='/' color='inherit' variant='h6' className={classes.link}>
              Capralín
            </Link>
          </Tooltip>
          <AccountCircleIcon onClick={handleClick} />
          <Menu
            id='profile-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleShowProfile}>{t('profile')}</MenuItem>
            <MenuItem onClick={logout}>{t('logout')}</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles(theme => {
  const drawerWidth = 260

  return {
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    container: {
      flex: 1
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    link: {
      textDecoration: 'none'
    }
  }
})

MobileAppBar.propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired
}

export default MobileAppBar
