const createFormat = ({ name, tenant_id }) => {
  return {
    variables: {
      object: {
        name,
        tenant_id
      }
    }
  }
}

const updateFormat = ({ id, name }) => {
  return {
    variables: {
      id,
      name
    }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id,
      deleted_at: new Date()
    }
  }
}

export default {
  createFormat,
  updateFormat,
  deleteFormat
}
