import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

function BaseButton(props) {
  return <Button fullWidth variant='contained' color='primary' {...props} />
}

BaseButton.propTypes = {
  props: PropTypes.object
}

export default BaseButton
