import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'

function BaseTable({ ...props }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <MaterialTable
      title=''
      localization={{
        toolbar: {
          searchTooltip: t('searchTooltip'),
          searchPlaceholder: t('searchPlaceholder')
        },
        pagination: {
          labelRowsSelect: t('labelRowsSelect'),
          firstAriaLabel: t('firstAriaLabel'),
          firstTooltip: t('firstTooltip'),
          lastAriaLabel: t('lastAriaLabel'),
          lastTooltip: t('lastTooltip'),
          previousAriaLabel: t('previousAriaLabel'),
          previousTooltip: t('previousTooltip'),
          nextAriaLabel: t('nextAriaLabel'),
          nextTooltip: t('nextTooltip')
        },
        body: {
          emptyDataSourceMessage: t('emptyDataSourceMessage')
        },
        header: {
          actions: ''
        }
      }}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30],
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white
        }
      }}
      {...props}
    />
  )
}

export default BaseTable
